<template>
  <div>
    <div class="header">
      <div>
        <el-page-header @back="goBack" content="数据权限配置"> </el-page-header>
      </div>
      <div>
        <el-button
          size="small"
          @click="roleAdd()"
          type="primary"
          icon="el-icon-plus"
          >新建</el-button
        >
        <el-button
          v-if="!isEdit"
          icon="el-icon-edit"
          size="small"
          @click="toEdit"
          :disabled="!this.subject"
          >编辑</el-button
        >
        <el-button
          v-if="isEdit"
          icon="el-icon-upload2"
          size="small"
          type="primary"
          @click="dataRightsSubmit()"
          >保存</el-button
        >
      </div>
    </div>
    <div style="display: flex; margin-top: 20px">
      <div style="width: 240px; overflow: auto">
        <el-menu default-active="0-0" class="el-menu-vertical-demo">
          <el-submenu
            :index="index1.toString()"
            v-for="(item, index1) in roleList"
            :key="index1"
          >
            <template slot="title">
              <span>{{ item.remark }} （{{ item.children.length }}）</span>
            </template>
            <el-menu-item
              :index="index1.toString() + '-' + index.toString()"
              @click="nameClick(item)"
              v-for="(item, index) in item.children"
              :key="index"
              >{{ item.name | filterName }}</el-menu-item
            >
          </el-submenu>
        </el-menu>
      </div>
      <div id="editor" style="width: 100%; height: 800px; overflow: auto"></div>
    </div>
    <addAccess :dialogVisible.sync="dialogVisible" @func="addFunc"></addAccess>
  </div>
</template>

<script>
import * as lodash from 'lodash';
import {
  dataRightsDescribe,
  dataRightsAdd,
  dataRightsList,
  dataRightsEdit,
  dataRightsDel
} from '../../api';
import { loadjs } from '../../lib/load';
import addAccess from './add-access';
export default {
  components: {
    addAccess
  },
  data() {
    return {
      editor: null,
      dialogVisible: false,
      newSubject: '',
      subject: '',
      remark: '',
      roleList: [],
      nameList: ['contract', 'enterprise', 'invoice', 'mime', 'task'],
      name: '',
      roleData: {},
      activeIndex: null,
      activeIndex2: null,
      newBtn: false,
      code: '',
      isEdit: false
    };
  },
  created() {
    this.getDataRightsList();
    loadjs(
      'https://cdn.jsdelivr.net/npm/monaco-editor@0.22.3/min/vs/loader.js'
    ).then(() => {
      window.require.config({
        paths: {
          vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.22.3/min/vs'
        },
        'vs/nls': { availableLanguages: { '*': 'zh-cn' } }
      });

      window.require(['vs/editor/editor.main'], () => {
        window.monaco.languages
          .getLanguages()
          .map(function(lang) {
            return lang.id;
          })
          .sort();
        this.editor = window.monaco.editor.create(
          document.getElementById('editor'),
          {
            value: '',
            readOnly: true,
            language: 'json',
            automaticLayout: true,
            theme: 'vs',
            minimap: {
              enabled: true
            }
          }
        );
      });
    });
  },
  filters: {
    filterName(item) {
      const map = {
        contract: '合同',
        contractTemplate: '合同模板',
        enterprise: '企业',
        dashBoard: '看板',
        user: '用户',
        task_job: '任务',
        setting: '设置',
        trade: '交易',
        task: '任务',
        customer: '客户管理',
        content: '内容管理',
        myBank: '开户',
        recruit: '招聘'
      };
      return map[item] || item;
    }
  },
  methods: {
    roleAdd() {
      this.dialogVisible = true;
    },
    addFunc() {
      this.getDataRightsList();
    },
    async getDataRightsList() {
      const result = await dataRightsList({
        pageSize: 1000,
        pageNumber: 1
      });
      console.log(lodash.groupBy(result.list || [], 'remark'));
      this.roleList = lodash(result.list || [])
        .groupBy('remark')
        .map((value, key) => {
          return { remark: key, children: value };
        })
        .value();
    },
    async nameClick(item, index) {
      console.log(item);
      this.subject = item.subject;
      this.name = item.name;
      this.code = item.code;
      this.remark = item.remark;
      this.activeIndex2 = index;
      if (!this.newBtn) {
        const result = await dataRightsDescribe({
          subject: item.subject,
          name: item.name
        });
        if (result.data) {
          this.editor.setValue(
            JSON.stringify(JSON.parse(result.data), undefined, 4)
          );
        } else {
          this.editor.setValue(JSON.stringify({ fn: [] }, undefined, 4));
        }
      } else {
        this.editor.setValue('{"fn":[]}');
      }
      this.editor.updateOptions({ readOnly: true });
      this.isEdit = false;
      setTimeout(() => {
        this.editor.getAction(['editor.action.formatDocument'])._run();
      }, 0);
    },
    toEdit() {
      this.isEdit = true;
      this.editor.updateOptions({ readOnly: false });
    },
    async dataRightsSubmit() {
      console.log(this.code);
      if (!this.subject || !this.name) {
        return this.$message.error('请选择角色信息');
      }
      if (this.newBtn) {
        await dataRightsAdd({
          subject: this.subject,
          name: this.name,
          data: JSON.stringify(JSON.parse(this.editor.getValue()))
        });
        this.$message.success('新增成功');
      } else {
        if (!this.code) return this.$message.error('请选择code');
        await dataRightsEdit({
          code: this.code,
          subject: this.subject,
          name: this.name,
          data: JSON.stringify(JSON.parse(this.editor.getValue()))
        });
        this.$message.success('编辑成功');
      }
    },
    async roleDel(item) {
      // if (!this.code) {
      //   return this.$message.error('该数据无code，请先创建')
      // }
      await dataRightsDel({
        code: item.code
      });
      this.$message.success('删除成功');
      this.getDataRightsList();
    },
    goBack() {
      window.history.go(-1);
    }
  }
};
</script>

<style scoped>
.card-header {
  border: 1px solid #ebeef5;
  background: #f7f7f7;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
}
.active {
  background: #eaf5ff;
}
.add-button {
  padding-left: 20px;
  text-align: center;
  border: 1px solid #eee;
  margin-right: 5px;
  margin-top: 20px;
}
</style>
