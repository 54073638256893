<template>
  <el-dialog title="新建数据权限" :visible="dialogVisible" width="600px">
    <el-form
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-width="80px"
    >
      <el-form-item label="主体" prop="subject">
        <el-input v-model="ruleForm.subject"></el-input>
      </el-form-item>
      <el-form-item label="模块" prop="name">
        <el-select v-model="ruleForm.name" style="width: 100%">
          <el-option
            v-for="(item, index) in nameList"
            :key="index"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ruleForm.remark"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog()">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import moment from 'moment';
import * as _ from 'lodash';
import { dataRightsAdd } from '../../api';
export default {
  data() {
    return {
      nameList: [
        'trade',
        'dashBoard',
        'contract',
        'enterprise',
        'invoice',
        'task_job',
        'user',
        'setting',
        'contractTemplate',
        'task',
        'customer',
        'content',
        'myBank',
        'recruit'
      ],
      ruleForm: {
        subject: '',
        name: '',
        remark: ''
      },
      rules: {
        subject: [],
        name: [],
        remark: []
      }
    };
  },
  props: {
    dialogVisible: {
      dialogFormVisible: false
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {};
          Object.keys(this.ruleForm).forEach(key => {
            const it = this.ruleForm[key];
            if (_.isDate(it)) {
              params[key] = moment(it).format('YYYY-MM-DD HH:mm');
              return;
            }
            if (_.isArray(it)) {
              params[key] = it.join(',');
              return;
            }
            params[key] = it;
          });
          dataRightsAdd(params).then(res => {
            console.log(res);
            this.closeDialog();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    closeDialog() {
      this.$emit('update:dialogVisible', false);
      this.$emit('func', this.dialogVisible);
    }
  }
};
</script>

<style>
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
.el-dialog__body {
  max-height: 500px;
  overflow: auto;
  padding: 10px 40px;
}
</style>
